

















import { isMobile } from '@/@core/helpers/useBreakpoint'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    components: {  },
    props: {
        'hide-on-mobile': {
            type: Boolean,
            default: false
        },
        'reverse': {
            type: Boolean,
            default: false
        },
    },
    name: 'KTableActions',
    setup(props) {
        // @ts-ignore
        const show = computed(() => !(isMobile.value && props.hideOnMobile))

        return {
            show,
            isMobile
        }
    }
})
