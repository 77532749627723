


































import { defineComponent } from '@vue/composition-api'
import KCard from '../other/KCard.vue'

export default defineComponent({
  components: { KCard },
    name: 'KTableWrapper',
    setup() {
        //

        return {
            //
        }
    }
})
