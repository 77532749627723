








































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useModelValue } from "@/@core/helpers/modelValue"
import KTextInput from '../input/KTextInput.vue'
import KText from '../typography/KText.vue'
import { useDebounce } from '@vueuse/core'

export default defineComponent({
    components: { KTextInput, KText },
    
    name: 'KTableSearch',
    props: {
        'placeholder': {
            type: String,
            default: 'Cari...'
        },
        'cancel-text': {
            type: String,
            default: 'Batal'
        },
    },
    setup(props, { emit, attrs }) {
        const modelValue = useModelValue({ emit, attrs })
        const tempValue = ref('')
        const debouncedValue = useDebounce(tempValue, 500)
        const reRenderCount = ref(0)

        watch(() => debouncedValue.value, v => updateModelValue(v))

        const updateModelValue = (v: any) => modelValue.value = v
        const clearModelValue = () => {
            tempValue.value = ''
            modelValue.value = ''
            emit('cancel', null)
            reRenderCount.value ++
        }

        onMounted(() => tempValue.value = modelValue.value)

        return {
            modelValue,
            tempValue,
            updateModelValue,
            clearModelValue,
            useDebounce,
            reRenderCount
        }
    }
})
