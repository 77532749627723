






































































import KFileInput from '@/@core/components/input/KFileInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import {
  defineComponent,
  Ref,
  ref,
  unref
} from '@vue/composition-api';

import readXlsxFile from 'read-excel-file';
import { useClassificationStore } from '../../services/store';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KPageBody,
    KWrapper,
    KHamburgerMenu,
    KText,
    KCard,
    KFileInput,
    KTableSearch,
    KTableActions,
    KTableWrapper,
    KTextInput
  },
  name: 'ClassificationImportPage',
  setup() {
    const dataStartFromRow = ref(1);
    const store = useClassificationStore();
    const resultJson: Ref<any[]> = ref([]);
    const isLoading = ref(false);
    const loadedExcel: Ref<unknown> = ref(null);
    const search = ref(null);
    const headers = [
      {
        text: 'Kode',
        value: 'code'
      },
      {
        text: 'KBLI',
        value: 'title'
      }
    ];

    const map = {
      No: 'no',
      'NAMA PERUSAHAAN': 'name',
      'ALAMAT PERUSAHAAN': 'address',
      'TITIK KOORDINAT': 'geolocation',
      NPWP: 'npwp',
      'NAMA PENANGGUNG JAWAB': 'inChargeName',
      'NAMA PEMILIK': 'ownerName',
      'BENTUK BADAN USAHA': 'businessEntity',
      'NO. TELEPON/HP': 'phoneNumber',
      KBLI: 'classifications',
      'PERIZINAN YANG DIMILIKI': 'permissions',
      'SOSIAL MEDIA': 'socialMedias',
      L: 'maleEmployee',
      P: 'femaleEmployee',
      TOTAL: 'totalEmployee',
      'NILAI INVESTASI': 'investmentValues',
      'BAHAN BAKU': 'materials',
      PRODUK: 'products',
      JUMLAH: 'productionPerYear',
      SATUAN: 'productionUnit',
      'HARGA PENJUALAN PERUNIT': 'pricePerUnit',
      PERTAHUN: 'valuePerYear',
      'TOTAL,': 'totalProductionValue',
      null: 'null',
      KET: 'additional'
    };

    const convert = () => {
      resultJson.value = [];

      if (!loadedExcel.value) return;

      const file = loadedExcel.value as File;

      readXlsxFile(file).then((rows) => {
        let data: any[] = [];
        rows.map((row: any) =>
          data.push({
            code: row[0],
            title: row[1]
          })
        );

        data = data.slice(dataStartFromRow.value);

        resultJson.value = data;
      });
    };

    const onSubmit = async () => {
      isLoading.value = true;

      const dataPerPartial = 100;

      let importedData = 0;
      const totalData = [...resultJson.value].length;

      const getFirstNData = (array: any[], n: number) => {
        const partialData = array.slice(0, n);

        return partialData;
      };

      const removeFirstNData = (array: any[], n: number) =>
        array.splice(0, n);

      while (
        resultJson.value.length > 0 &&
        resultJson.value.length !== importedData
      ) {
        const partialData = getFirstNData(
          [...resultJson.value],
          dataPerPartial
        );

        const response = await store.importClassification(
          partialData
        );

        if (response) {
          importedData = totalData - resultJson.value.length;

          useUiStateStore().createSnackbar({
            message: `${importedData}/${totalData} KBLI berhasil diimport`
          });
        }

        importedData += partialData.length;

        useUiStateStore().createSnackbar({
          message: `${importedData}/${totalData} KBLI berhasil diimport`
        });

        removeFirstNData(resultJson.value, partialData.length);
      }

      isLoading.value = false;
    };

    return {
      isMobile,
      loadedExcel,
      resultJson,
      convert,
      headers,
      search,
      isLoading,
      onSubmit,
      dataStartFromRow
    };
  }
});
